import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import Therapist from '../components/Team/components/Therapist';
import ShortBio from '../components/Team/components/ShortBio';
import FullBio from '../components/Team/components/FullBio';

const TherapistFull = ({ pageContext }) => {
  const { therapist } = pageContext;
  const { name, credentials, education1, education2, info, route, metaDescription } = therapist;

  const shortBio = <ShortBio name={route} />;
  const fullBio = <FullBio name={route} />;

  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';
  const image = `https://res.cloudinary.com/dewd4pral/image/upload/q_60,c_scale,h_200,w_200/${therapist.image}`;

  return (
    <>
      <SEO
        title={`${name} | Mindset Family Therapy`}
        description={metaDescription}
        image={image}
        pathname={`/team/${route}`}
      />
      <Layout>
        <main>
          <PageHeader
            pageCategory="About"
            pageName={_.head(_.split(name, ' '))}
            headerImage={background}
          />
          <Therapist
            name={name}
            credentials={credentials}
            image={image}
            education1={education1}
            education2={education2}
            info={info}
            shortBio={shortBio}
            fullBio={fullBio}
            backgroundColor="#F4F8F9"
            useFullBio
          />
        </main>
      </Layout>
    </>
  );
};

export default TherapistFull;

TherapistFull.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
